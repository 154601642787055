exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-hello-ts-tsx": () => import("./../../../src/pages/hello-ts.tsx" /* webpackChunkName: "component---src-pages-hello-ts-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mywork-tsx": () => import("./../../../src/pages/mywork.tsx" /* webpackChunkName: "component---src-pages-mywork-tsx" */),
  "component---src-pages-oldindex-js": () => import("./../../../src/pages/oldindex.js" /* webpackChunkName: "component---src-pages-oldindex-js" */),
  "component---src-pages-resume-tsx": () => import("./../../../src/pages/resume.tsx" /* webpackChunkName: "component---src-pages-resume-tsx" */),
  "component---src-templates-blog-post-index-tsx": () => import("./../../../src/templates/BlogPost/index.tsx" /* webpackChunkName: "component---src-templates-blog-post-index-tsx" */)
}

